import { css } from 'styled-components';
import { atMinWidth } from '../../atoms/breakpoints';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';

export const wrapperStyles = (kickerColor, blackSubhead, isDark) => css`
  p {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    justify-content: inherit;
  }

  .heading-info {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    gap: 32px;
    margin-bottom: 32px;
    max-width: 800px;

    .row {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }

  .heading-kicker {
    ${font('overline', 'md', '700')}
    color: ${colors.primary[500]};
  }

  .heading {
    ${font('display', 'lg', '700')}
    color: ${isDark ? colors.base.white : colors.gray[900]};

    ${atMinWidth.xl`
        ${font('display', 'xl', '700')}
    `}
  }

  .subhead {
    color: ${
      blackSubhead
        ? 'var(--text-color)'
        : isDark
        ? colors.base.white
        : 'var(--subtle-text-color)'
    };
    ${font('text', 'md', '400')}

    ${atMinWidth.sm`
      ${font('text', 'lg', '400')}
    `}

    ${atMinWidth.md`
      ${font('text', 'xl', '400')}
    `}
  }

  .list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 1010px;
    margin: 0px auto;
    padding-bottom: 0px;

    ${atMinWidth.md`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 35px;
    `}

    .list-item {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: center;

      .list-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40px;
      }

      .list-featured-text {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        ${font('display', 'xl', '700')}
        margin-bottom: 0px;
      }

      .list-text {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .list-heading {
        ${font('display', 'sm', '700')}
        color: #1d2030;
        margin: 12px 0;
      }

      .list-subhead {
        ${font('text', 'sm', '400')}
        color: #6f7283;
        max-width: 270px;

        p {
          margin: 0px;
        }
      }
    }
  }
`;
